/* eslint-disable */

/**
 * All things jquery (extensions/plugins)
 *
 * @note jquery already globally provided => config/webpack/custom.js
 * BUT ONLY FOR MODULES, not for "scripts". But some of
 * these are not modules, are regular scripts,
 * so webpacks providePlugin can't expose jquery to them.
 * Also providePlugin doens't make jquery globally exposed to window,
 * only for modules.
 */

// [1] expose jquery to a global window variable
import "./jquery-expose";

/**
 * JQUERY-MIGRATE
 *
 * Fix deprecated methods in jQuery >=3.0
 * Deprecated methods to refactor!
 *
 *    $.fn.load
 *    $.browser
 *    $.ready
 *
 * @link https://jquery.com/upgrade-guide/3.0/#breaking-change-load-unload-and-error-removed
 * @link https://stackoverflow.com/questions/37738732/jquery-3-0-url-indexof-error
 * @note [1] - the main version outputs log to console
 */
if (process.env.NODE_ENV === "development") {
  $.migrateTrace = false; // shorter warnings, enable for full stack trce
  require("jquery-migrate"); // [1]
} else {
  // silence jquery-migration version loggin
  const logger = window.console.log;
  window.console.log = () => {}; // use noop, keep same type;
  require("jquery-migrate/dist/jquery-migrate.min.js");
  window.console.log = logger;
}

// order matters
require("@vendor/javascripts/jquery.sparkline");

/**
 * JQUERY-UJS
 *
 * "unobtrusive javascritp"
 * @todo [1] - Check if suggested package `rail-ujs` replacement works
 */
import "jquery-ujs"; // [1];
import "@vendor/javascripts/jquery.iframe-transport";
import "@vendor/javascripts/jquery.remotipart";


/**
 * jQuery-ui cherry-pick
 */
import "jquery-ui/ui/core";
import "jquery-ui/ui/widget";
import "jquery-ui/ui/widgets/draggable";
import "jquery-ui/ui/widgets/slider";
import "@vendor/javascripts/jquery.ui.touch-punch.min";

/**
 * ANCIENT INLINE JQUERY PLUGINS
 *
 * @todo If found compatible npm module, replace it here
 */
import "@vendor/javascripts/autosize";
import "@vendor/javascripts/jquery.railsremote";
import "@vendor/javascripts/jquery.cookie";
import "@vendor/javascripts/jquery.flashMessages";
import "@vendor/javascripts/jquery.are-you-sure";
