# Render recaptcha
$(window).load ->
  if (window.grecaptcha && $('#user_recaptcha').is(':empty'))
    grecaptcha.ready(() ->
      grecaptcha.render('user_recaptcha', {
        'sitekey' : $('#user_recaptcha').data('recaptchakey')
      });
    )

$(document)
  .on 'ajax:error', '#new_user', (evt, xhr, status, err) ->

    try
      data = $.parseJSON(xhr.responseText)

      $('#new_session_user_email').val($('#user_email').val())
      $('#new_session_user_password').focus()

      errors = data.errors.user
      errmsg = ""

      $.each data.header_errors, (k,v) ->
        errmsg += "<br>" if k > 0
        errmsg += "#{v}"

      $('#new-user-msgbox').flashMessages(
        message: errmsg,
        template: msgboxerror,
        afterShow: () ->
          $.fancybox.reposition()
      )

      $('.error-message', this).remove()
      $('.error', this).removeClass('error')
      $.each errors, (key, value)->
        if value[0]?
          $element = $("#user_#{key}")
          $element.addClass('error')
          if $element.next('.error-message').length is 0
            $element.after("<span class=\"error-message\">#{value[0]}</span>")

      # Reset recaptcha after request
      if (window.grecaptcha && $('#user_recaptcha').is(':visible'))
        grecaptcha.reset($('#user_recaptcha').attr('captcha-id'))

    catch e
      $('#new-user-msgbox').flashMessages({
        message:  "Oops, something went wrong on our side. Please try again or <a href='/contact'>contact us</a> for help",
        template: msgboxerror,
        afterShow: () ->
          $.fancybox.reposition()
      });


  .on 'ajax:success', '#new_user', (event, data, status , xhr) ->
    # delegate redirect to ajax:complete
    $(this).data('redirect', true)

  .on 'ajax:success', '#edit_user, #edit_user_email_preferences', (event, data, status, xhr) ->
    $('[data-js="user-profile-err-box"]').html('')
    $('[data-main]').css("margin-top", "0px")
    Alert.success 'Your account was updated successfully'

  .on 'ajax:error', '#edit_user', (event, data, status, xhr) ->
    $('[data-js-header-alerts-container]').empty()
    err_content = ""
    errors = {}

    try
      errors = JSON.parse(data.responseText).errors
      err_content = 'We could not update your personal settings. Please correct the following:'
    catch e
      err_content = "Oops, something went wrong on our side. Please try again or <a href='/contact'>contact us</a> for help"

    $('[data-flash-editUser] [data-js="user-profile-err-box"]').flashMessages({ message: err_content, template: msgboxerror, errors: Object.values(errors) });
    heightMsgContent = $('[data-js="user-profile-err-box"]').outerHeight(true)
    $('[data-main]').css("margin-top", heightMsgContent);

  # change password feature

  .on 'ajax:error', '#edit_password', (event, data, status, error) ->
    err_content = ""
    errors = {}

    try
      errors = JSON.parse(data.responseText).errors
      action_txt = if($('#edit_password').hasClass('create-pw-form'))
                     ' create '
                    else
                     ' update '

      err_content = 'We could not' + action_txt + 'your password. Please correct the following:'
    catch e
      err_content = "Oops, something went wrong on our side. Please try again or <a href='/contact'>contact us</a> for help"

    $('#password-box [data-dialog-msgbox]').flashMessages({ message: err_content, template: msgboxerror, errors: Object.values(errors) });

  .on 'ajax:success', '#edit_password', (event, data, status , xhr) ->
    $(this).data('redirect', true)

  .on 'click', 'a.oauth-disconnect-link', ->
    return false
